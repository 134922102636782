"use client";
import { useUniqueCompareCount, useUniqueProductCount, useUniqueWishlistCount } from "@/hooks/useCartQuantity";
import useGlobalContext from "@/hooks/use-context";
import Link from "next/link";
import Image from "next/image";
import MobileMenu from "./MobileMenu";
import SidebarMenuSearch from "./SidebarMenuSearch";
import logo from "../../../../public/assets/img/logo/logo.png"; // Adjust the path if necessary

const SidebarMenu = () => {
  const { sideMenuOpen, setSideMenuOpen } = useGlobalContext();
  const totalCart = useUniqueProductCount();
  const totalWishlist = useUniqueWishlistCount(); // Corrected the function name here
  const totalCompare = useUniqueCompareCount();

  return (
    <div className="fix">
      <div className={sideMenuOpen ? "side-info info-open" : "side-info"}>
        <div className="side-info-content">
          <div className="offset-widget offset-logo">
            <div className="row align-items-center">
              <div
                onClick={() => setSideMenuOpen(!sideMenuOpen)}
                className="col-3 text-end"
              >
                <button className="side-info-close">
                  <i className="fal fa-times"></i>
                </button>
              </div>
            </div>
          </div>
          <div className="mobile-menu d-lg-none fix mean-container">
            <SidebarMenuSearch />
            <div className="mean-bar">
              <nav className="mean-nav">
                <MobileMenu />
              </nav>
            </div>
          </div>
         
          <div className="social__links header-bottom-social">
            <ul>
              <li>
                <Link href="https://www.facebook.com/kosquadegyptgear?mibextid=ZbWKwL" target="_blank">
                  <i className="fab fa-facebook-f"></i>
                </Link>
              </li>
              <li>
                <Link href="https://www.instagram.com/kogear.shop?igsh=MTQ2bDhwaTBoYWNsOQ==" target="_blank">
                  <i className="fab fa-instagram"></i>
                </Link>
              </li>
              <li>
                <Link href="https://www.tiktok.com/@kogear.shop?_t=8pZuSzmDXAW&_r=1" target="_blank">
                  <i className="fab fa-tiktok"></i>
                </Link>
              </li>
            </ul>
             <span className="text-center copy-right-mobily">
                 Copyright by Ko Squad. All Rights Reserved
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SidebarMenu;
