'use client';

import menu_data from "@/data/menu-data";
import useGlobalContext from "@/hooks/use-context";
import Link from "next/link";
import React, { useState } from "react";

const MobileMenu = () => {
  const { toggleSideMenu } = useGlobalContext();
  const [openSubMenu, setOpenSubMenu] = useState<number | null>(null);
  const [openMegaMenu, setOpenMegaMenu] = useState<number | null>(null);

  const handleActiveSubMenu = (index: number) => {
    setOpenSubMenu((prev) => (prev === index ? null : index));
    setOpenMegaMenu(null);
  };

  const handleActiveMegaMenu = (index: number) => {
    setOpenMegaMenu((prev) => (prev === index ? null : index));
  };

  return (
    <ul className="mobile-menu">
      {menu_data?.map((item) => (
        <li
          key={item.id}
          className={`${item.className || ""} ${
            item.hasDropdown ? "menu-item-has-children" : "mean-last"
          }`}
        >
          <div className="menu-item-wrapper">
            {/* If the item has a dropdown, make the entire link clickable to toggle submenu */}
            {item.hasDropdown ? (
              <button
                onClick={() => handleActiveSubMenu(item.id)}
                className="menu-link-button"
                aria-expanded={openSubMenu === item.id}
              >
                {item.title}
                {/* Collaborate Label */}
                {item.collaborate && (
                  <span className="collaborate-label">collaboration</span>
                )}
              </button>
            ) : (
              <Link onClick={toggleSideMenu} href={item.link} className="menu-link">
                {item.title}
                {/* Collaborate Label */}
                {item.collaborate && (
                  <span className="collaborate-label">collaboration</span>
                )}
              </Link>
            )}

            {/* Plus/Minus Icon */}
            {item.hasDropdown && item.pluseIncon && (
              <button
                onClick={() => handleActiveSubMenu(item.id)}
                className="mean-expand"
                aria-label={openSubMenu === item.id ? "Collapse submenu" : "Expand submenu"}
                aria-expanded={openSubMenu === item.id}
              >
                <i
                  className={`fal ${
                    openSubMenu === item.id ? "fa-minus" : "fa-plus"
                  }`}
                ></i>
              </button>
            )}
          </div>

          {/* Submenu */}
          {item.hasDropdown && item.submenus && (
            <ul
              className="sub-menu"
              style={{
                display: openSubMenu === item.id ? "block" : "none",
              }}
            >
              {item.submenus.map((subItem, subIndex) => (
                <li
                  key={subIndex}
                  className={`sub-menu-item ${
                    subItem.megaMenu ? "menu-item-has-children" : ""
                  }`}
                >
                  <div className="submenu-item-wrapper">
                    {/* If the submenu has a mega menu, make the entire link clickable to toggle mega submenu */}
                    {subItem.megaMenu ? (
                      <button
                        onClick={() => handleActiveMegaMenu(subIndex)}
                        className="submenu-link-button"
                        aria-expanded={openMegaMenu === subIndex}
                      >
                        {subItem.title}
                        {/* Collaborate Label for Submenus */}
                        {subItem.collaborate && (
                          <span className="collaborate-label">collaboration</span>
                        )}
                      </button>
                    ) : (
                      <Link onClick={toggleSideMenu} href={subItem.link} className="submenu-link">
                        {subItem.title}
                        {/* Collaborate Label for Submenus */}
                        {subItem.collaborate && (
                          <span className="collaborate-label">collaboration</span>
                        )}
                      </Link>
                    )}

                    {/* Plus/Minus Icon for Mega Menu */}
                    {subItem.megaMenu && subItem.pluseIncon && (
                      <button
                        onClick={() => handleActiveMegaMenu(subIndex)}
                        className="mean-expand"
                        aria-label={openMegaMenu === subIndex ? "Collapse mega menu" : "Expand mega menu"}
                        aria-expanded={openMegaMenu === subIndex}
                      >
                        <i
                          className={`fal ${
                            openMegaMenu === subIndex ? "fa-minus" : "fa-plus"
                          }`}
                        ></i>
                      </button>
                    )}
                  </div>

                  {/* Mega Menu */}
                  {subItem.megaMenu && subItem.megaMenuData && (
                    <ul
                      className="mega-menu"
                      style={{
                        display: openMegaMenu === subIndex ? "block" : "none",
                      }}
                    >
                      {subItem.megaMenuData.map((megaItem, megaIndex) => (
                        <li key={megaIndex}>
                          <Link
                            onClick={toggleSideMenu}
                            href={megaItem.link}
                            className="link-menu"
                          >
                            {megaItem.title}
                            {/* Collaborate Label for Mega Menus */}
                            {megaItem.collaborate && (
                              <span className="collaborate-label">collaborate</span>
                            )}
                          </Link>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

export default MobileMenu;
